<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <button class="close-button" @click="close">&times;</button>
      <h2>Boosts</h2>
      <p class="boost-description">Boosts are temporary enhancements that will help you reach your goals faster. Use them wisely!</p>
      <div class="boost-list">
        <div class="boost-item">
          <div class="boost-info">
            <Zap class="boost-icon" />
            <div class="boost-details">
              <div class="boost-title">Boost your Taps</div>
              <div class="boost-description">Increase your taps power for 5 minutes</div>
            </div>
          </div>
          <div class="boost-action">
            <button 
              class="boost-button" 
              @click="handleBoostAction('tap_power')" 
              :disabled="isLoading"
              :class="{ 'ads-button': tapPowerBoosts === 0 }"
            >
              <template v-if="tapPowerBoosts > 0">
                {{ tapPowerBoosts }}/2
              </template>
              <template v-else>
                <AdIcon class="ads-icon" />
                Watch Ad
              </template>
            </button>
            <div v-if="tapPowerBoosts < 2" class="cooldown-timer">
              {{ formattedTapPowerCooldown }}
            </div>
          </div>
        </div>
        <div class="boost-item">
          <div class="boost-info">
            <Battery class="boost-icon" />
            <div class="boost-details">
              <div class="boost-title">Refill Energy</div>
              <div class="boost-description">Instantly refill your energy</div>
            </div>
          </div>
          <div class="boost-action">
            <button 
              class="boost-button" 
              @click="handleBoostAction('energy_refill')" 
              :disabled="isLoading"
              :class="{ 'ads-button': energyRefillBoosts === 0 }"
            >
              <template v-if="energyRefillBoosts > 0">
                {{ energyRefillBoosts }}/1
              </template>
              <template v-else>
                <AdIcon class="ads-icon" />
                Watch Ad
              </template>
            </button>
            <div v-if="energyRefillBoosts < 1" class="cooldown-timer">
              {{ formattedEnergyRefillCooldown }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="error" class="error-message">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { Zap, Battery, PlayCircle as AdIcon } from 'lucide-vue-next'
import { globalSettings } from '../../globalSettings';

export default {
  name: 'Boosts',
  components: {
    Zap,
    Battery,
    AdIcon
  },
  props: {
    show: Boolean
  },
  emits: ['close'],
  data() {
      return {
        globalSettings: globalSettings, // Сохраняем данные в локальном состоянии компонента
      };
  },
  setup(props, { emit }) {
    const store = useStore()
    const error = ref('')
    const isLoading = ref(false)

    const tapPowerBoosts = computed(() => store.getters.getTapPowerBoosts)
    const energyRefillBoosts = computed(() => store.getters.getEnergyRefillBoosts)
    const tapPowerCooldown = ref(0)
    const energyRefillCooldown = ref(0)

    const formattedTapPowerCooldown = ref('')
    const formattedEnergyRefillCooldown = ref('')

    const close = () => {
      emit('close')
    }

    const handleBoostAction = async (boostType) => {
      if ((boostType === 'tap_power' && tapPowerBoosts.value === 0) ||
          (boostType === 'energy_refill' && energyRefillBoosts.value === 0)) {
        await showAd(boostType)
      } else {
        await useBoost(boostType)
      }
    }

    const useBoost = async (boostType) => {
      isLoading.value = true
      error.value = ''

      try {
        const response = await store.dispatch('useBoost', boostType)

        if (response.success) {
          close()
          if (boostType === 'tap_power') {
            const boostDuration = 10 + response.boost_level
            store.state.tapPowerBoostEndTime = Date.now() + boostDuration * 1000
            store.state.tapPowerBoostMultiplier = response.boost_power + 1
            startTapPowerBoostTimer(boostDuration)
          }
        } else {
          error.value = response.message
        }
      } catch (e) {
        console.error('Ошибка при использовании буста:', e)
        error.value = 'Произошла ошибка при использовании буста. Попробуйте еще раз.'
      } finally {
        isLoading.value = false
      }
    }

    const startTapPowerBoostTimer = (duration) => {
      const timer = setInterval(() => {
        const remainingTime = Math.max(0, (store.state.tapPowerBoostEndTime - Date.now()) / 1000)
        if (remainingTime <= 0) {
          clearInterval(timer)
          store.state.tapPowerBoostMultiplier = 1
          store.state.tapPowerBoostEndTime = null
        }
      }, 1000)
    }

    const showAd = async (boostType) => {
      if (window.Adsgram) {
        const AdController = window.Adsgram.init({ blockId: globalSettings.adsgramId })
        try {
          await AdController.show()
          console.log('Реклама просмотрена')
          await store.dispatch('addBoostUse', boostType)
        } catch (error) {
          console.error('Ошибка показа рекламы', error)
        }
      } else {
        console.error('Adsgram не инициализирован')
      }
    }

    const formatTimeWithSeconds = (seconds) => {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const secs = Math.floor(seconds % 60)
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
    }

    const updateCooldowns = () => {
      tapPowerCooldown.value = Math.max(0, 4 * 60 * 60 - (Date.now() - store.state.lastTapPowerBoostUse) / 1000)
      energyRefillCooldown.value = Math.max(0, 4 * 60 * 60 - (Date.now() - store.state.lastEnergyRefillBoostUse) / 1000)
      formattedTapPowerCooldown.value = formatTimeWithSeconds(tapPowerCooldown.value)
      formattedEnergyRefillCooldown.value = formatTimeWithSeconds(energyRefillCooldown.value)
      if (store.state.lastTapPowerBoostUse !== null && tapPowerCooldown.value === 0 && tapPowerBoosts.value < 2) {
        try {
          store.dispatch('replenishBoosts', 'tap_power')
          store.dispatch('loadBoostData')
        } catch (error) {
          console.error('Ошибка при восполнении бустов:', error)
        }
      }
      if (store.state.lastEnergyRefillBoostUse !== null && energyRefillCooldown.value === 0 && energyRefillBoosts.value < 1) {
        try {
          store.dispatch('replenishBoosts', 'energy_refill')
          store.dispatch('loadBoostData')
        } catch (error) {
          console.error('Ошибка при восполнении бустов:', error)
        }
      }
    }

    let intervalId

    onMounted(() => {
      store.dispatch('loadBoostData')
      updateCooldowns()
      intervalId = setInterval(() => {
        updateCooldowns()
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(intervalId)
    })

    watch(() => props.show, (newValue) => {
      if (newValue) {
        store.dispatch('loadBoostData')
        updateCooldowns()
      }
    })

    watch(tapPowerCooldown, async (newValue) => {
      if (newValue === 0) {
        try {
          await store.dispatch('replenishBoosts', 'tap_power')
          store.dispatch('loadBoostData')
        } catch (error) {
          console.error('Ошибка при восполнении бустов:', error)
        }
      }
    })

    watch(energyRefillCooldown, async (newValue) => {
      if (newValue === 0) {
        try {
          await store.dispatch('replenishBoosts', 'energy_refill')
          store.dispatch('loadBoostData')
        } catch (error) {
          console.error('Ошибка при восполнении бустов:', error)
        }
      }
    })

    return {
      close,
      handleBoostAction,
      tapPowerBoosts,
      energyRefillBoosts,
      error,
      isLoading,
      formattedTapPowerCooldown,
      formattedEnergyRefillCooldown
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 350px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.boost-description {
  text-align: center;
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 15px;
  padding: 0 10px;
}

.boost-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 10px;
}

.boost-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f4f6;
  padding: 10px;
  border-radius: 8px;
}

.boost-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.boost-icon {
  width: 24px;
  height: 24px;
  color: #4CAF50;
}

.boost-details {
  display: flex;
  flex-direction: column;
}

.boost-title {
  font-weight: bold;
  font-size: 16px;
}

.boost-description {
  font-size: 12px;
  color: #6b7280;
}

.boost-button {
  width: 100px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.boost-button:not(:disabled) {
  background-color: #4CAF50;
  color: white;
}

.boost-button:hover:not(:disabled) {
  background-color: #45a049;
}

.boost-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.ads-button {
  background-color: #2196F3 !important;
}

.ads-icon {
  width: 18px;
  height: 18px;
  color: white;
  margin-right: 5px;
}

.cooldown-timer {
  font-size: 12px;
  color: #6b7280;
  margin-top: 4px;
  min-height: 15px; /* Добавляем минимальную высоту, чтобы избежать "прыжков" интерфейса */
}
</style>
