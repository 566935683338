<template>
<div class="notification" :class="{ 'show': showNotification }">
    {{text}}
</div>
</template>

<script>
import { ref, watch, defineEmits } from 'vue';

export default {
  name: 'SimpleNotification',
  props: {
    duration: {
      type: Number,
      default: 3000, // Длительность отображения уведомления в миллисекундах
    },
    text: {
      type: String,
      default: 'Simple notification',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const emit = defineEmits(); // Определяем emit
    const showNotification = ref(props.visible);

    // Слушаем изменения свойства visible
    watch(
      () => props.visible,
      (newValue) => {
        showNotification.value = newValue;
        if (newValue) {
          // Если уведомление становится видимым, скрываем его через заданное время
          setTimeout(() => {
            showNotification.value = false;
            emit('update:visible', false); // Отправляем событие в родительский компонент
          }, props.duration);
        }
      }
    );

    return {
      showNotification,
    };
  },
};
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 130px; /* Располагаем уведомление над кнопками */
  left: 1rem;
  right: 1rem;
  background-color: #4CAF50; /* Зеленый цвет */
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Чтобы уведомление не мешало взаимодействию с элементами под ним */
}

.notification.show {
  opacity: 1;
}
</style>