<template>
  <div class="wallet-container">
    <div class="wallet-header">
      <h2>Wallet</h2>
    </div>
    <div class="wallet-content">
      <div v-if="!walletInfo" class="connect-wallet">
        <button @click="connectWallet" class="connect-button">Connect Wallet</button>
      </div>

      <div v-else class="wallet-info">
        <div class="wallet-card" @click="showDisconnectModal">
          <div class="wallet-details">
            <div class="wallet-label">Wallet Connected</div>
            <div class="wallet-address">{{ shortenedAddress }}</div>
          </div>
          <div class="wallet-balance">{{ totalBalance.toFixed(2) }}</div>
        </div>
        <div class="divider"></div>
        <div class="currency-cards">
          <div class="currency-card">
            <div class="currency-icon">
              <DollarSign class="icon green" />
            </div>
            <div class="currency-info">
              <div class="currency-name">USDT</div>
              <div class="currency-amount">{{ usdtBalance.toFixed(6) }}</div>
            </div>
            <div class="currency-actions">
              <button class="currency-action withdraw" @click="requestWithdrawal" :disabled="usdtBalance < 3 || usdtWithdrawalBalance > 0">Withdraw</button>
              <div class="min-withdrawal" v-if="usdtWithdrawalBalance == 0">Min. withdrawal: $3</div>
              <div class="pending-withdrawal" v-if="usdtWithdrawalBalance > 0">Pending withdraw: ${{usdtWithdrawalBalance}}</div>
            </div>
          </div>
          <div class="currency-card">
            <div class="currency-icon">
              <Zap class="icon blue" />
            </div>
            <div class="currency-info">
              <div class="currency-name">TON</div>
              <div class="currency-amount">{{ tonBalance.toFixed(9) }}</div>
            </div>
            <button class="currency-action" disabled>Soon</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal window for wallet disconnection -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h3>Are you sure you want to disconnect the wallet?</h3>
        <button @click="disconnectWallet" class="disconnect-button">Disconnect</button>
        <button @click="showModal = false" class="cancel-button">Cancel</button>
      </div>
    </div>
    
    <div class="notification" :class="{ 'show': showNotification }">
      Запрос на вывод средств успешно отправлен
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { DollarSign, Zap } from 'lucide-vue-next'
import { toUserFriendlyAddress } from '@tonconnect/sdk';

export default {
  name: 'Wallet',
  components: {
    DollarSign,
    Zap
  },
  setup() {
    const store = useStore()
    const totalBalance = ref(0)
    const showModal = ref(false)
    const usdtBalance = ref(0)
    const usdtWithdrawalBalance = ref(0)
    const tonBalance = ref(0)
    const showNotification = ref(false)

    const walletInfo = computed(() => store.state.walletInfo)
    const tonConnector = computed(() => store.state.tonConnector)

    const shortenedAddress = computed(() => {
      if (walletInfo.value && walletInfo.value.account.address) {
        const address = toUserFriendlyAddress(walletInfo.value.account.address)
        return `${address.slice(0, 6)}...${address.slice(-4)}`
      }
      return ''
    })

    const connectWallet = async () => {
      try {
        await store.dispatch('connectWallet')
        await loadWalletData()
      } catch (e) {
        console.error('Ошибка при подключении кошелька:', e)
      }
    }

    const showDisconnectModal = () => {
      showModal.value = true
    }

    const disconnectWallet = async () => {
      try {
        await store.dispatch('disconnectWallet')
        showModal.value = false
        usdtBalance.value = 0
        tonBalance.value = 0
      } catch (e) {
        console.error('Ошибка при отключении кошелька:', e)
      }
    }

    const loadWalletData = async () => {
      try {
        const response = await store.dispatch('loadWalletData')
        usdtBalance.value = response.usdt_balance
        usdtWithdrawalBalance.value = response.usdt_withdrawal
        tonBalance.value = response.ton_balance
        totalBalance.value = usdtBalance.value + tonBalance.value + usdtWithdrawalBalance.value // Предполагаем, что 1 TON = 1 USD для простоты
      } catch (e) {
        console.error('Ошибка при загрузке данных кошелька:', e)
      }
    }

    const withdrawUSDT = async () => {
      // Реализация вывода USDT
      console.log('Запрос на вывод USDT')
    }

    const withdrawTON = async () => {
      // Реализация вывода TON
      console.log('Запрос на вывод TON')
    }

    const requestWithdrawal = async () => {
      try {
        const response = await store.dispatch('requestWithdrawal')
        if (response.success) {
          showNotification.value = true
          setTimeout(() => {
            showNotification.value = false
          }, 3000)
          await store.dispatch('loadWalletData')
        }
      } catch (error) {
        console.error('Ошибка при запросе на вывод средств:', error)
        alert('Произошла ошибка при запросе на вывод средств')
      }
    }

    onMounted(async () => {
      if (walletInfo.value) {
        await loadWalletData()
      }
    })

    return {
      walletInfo,
      totalBalance,
      usdtBalance,
      usdtWithdrawalBalance,
      tonBalance,
      connectWallet,
      shortenedAddress,
      showModal,
      showDisconnectModal,
      disconnectWallet,
      withdrawUSDT,
      withdrawTON,
      requestWithdrawal,
      showNotification
    }
  }
}
</script>

<style scoped>
.wallet-container {
  background-color: #c9aa71;
  min-height: 100vh;
  padding: 1rem;
}

.wallet-header {
  text-align: center;
  margin-bottom: 1rem;
  color: white;
}

.wallet-content {
  max-width: 400px;
  margin: 0 auto;
}

.connect-wallet {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.connect-button {
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.connect-button:hover {
  background-color: #2563eb;
}

.wallet-card {
  background-color: #4CAF50;
  color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
}

.wallet-details {
  text-align: left;
}

.wallet-label {
  font-size: 0.875rem;
  font-weight: bold;
}

.wallet-address {
  font-size: 0.875rem;
  opacity: 0.8;
}

.wallet-balance {
  font-size: 1.25rem;
  font-weight: bold;
}

.currency-cards {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.currency-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.currency-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.icon.yellow {
  color: #f59e0b;
}

.icon.green {
  color: #10b981;
}

.icon.blue {
  color: #3b82f6;
}

.currency-info {
  text-align: left;
  flex-grow: 1;
  margin-left: 1rem;
}

.currency-name {
  font-size: 1rem;
  font-weight: bold;
}

.currency-amount {
  font-size: 0.875rem;
  color: #6b7280;
}

.currency-action {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
}

.currency-action:disabled {
  background-color: #d1d5db;
  color: #6b7280;
  cursor: not-allowed;
}

.currency-action.withdraw:not(:disabled) {
  background-color: #3b82f6;
  color: white;
}

.currency-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.min-withdrawal {
  font-size: 0.625rem;
  color: #6b7280;
  margin-top: 0.25rem;
}

.pending-withdrawal {
  font-size: 0.825rem;
  color: #6b7280;
  margin-top: 0.25rem;
}

.divider {
  height: 1px;
  background-color: #e5e7eb;
  margin: 1rem 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
}

.disconnect-button, .cancel-button {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.disconnect-button {
  background-color: #ef4444;
  color: white;
}

.cancel-button {
  background-color: #9ca3af;
  color: white;
}

.notification {
  position: fixed;
  bottom: 130px; /* Располагаем уведомление над кнопками */
  left: 1rem;
  right: 1rem;
  background-color: #4CAF50; /* Зеленый цвет */
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Чтобы уведомление не мешало взаимодействию с элементами под ним */
}

.notification.show {
  opacity: 1;
}
</style>
