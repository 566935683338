<template>
  <div class="game-container">
    <div class="content-wrapper">
      <Head />
      
      <div class="game-area">
        <div class="skuff-container">
          <img src="@/static/skuff.png" alt="Skuff" class="skuff-image" />
          <div class="click-area-wrapper">
            <div class="glow-effect"></div>
            <div
              @click="handleClick"
              class="click-area"
              :class="{ 'clicked': isClicked }"
            >
              <img src="@/static/puzo.png" alt="Puzo" class="puzo-image" />
              <FloatingNumber
                v-for="num in floatingNumbers"
                :key="num.id"
                :x="num.x"
                :y="num.y"
                :amount="num.amount"
              ></FloatingNumber>
            </div>
          </div>
        </div>
      </div>
      
      <div class="progress-boost">
        <div class="boost-button">
          {{ Math.floor(availableTaps) }}/{{ availableMax }}
        </div>
        <button @click="showBoosts = true" class="boost-button">
          Boosts
        </button>
      </div>
      
      <NavBar />
    </div>
    <Boosts :show="showBoosts" @close="showBoosts = false" />
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import FloatingNumber from './FloatingNumber.vue'
import NavBar from './NavBar.vue'
import Head from './Head.vue'
import Boosts from './Boosts.vue'

export default {
  name: 'SkuffGame',
  components: {
    FloatingNumber,
    NavBar,
    Head,
    Boosts
  },
  setup() {
    const store = useStore()
    const isClicked = ref(false)
    const floatingNumbers = ref([])
    const updateInterval = ref(null)
    const tapTimeout = ref(null)
    const showBoosts = ref(false)

    const availableTaps = computed(() => store.state.availableTaps)
    const availableMax = computed(() => store.state.availableMax)

    const handleClick = (event) => {
      if (store.getters.canTap) {
        const rect = event.currentTarget.getBoundingClientRect()
        const x = ((event.clientX - rect.left) / rect.width) * 100
        const y = ((event.clientY - rect.top) / rect.height) * 100

        isClicked.value = true
        const baseTokens = store.state.userData.token_per_tap || 1
        const boostMultiplier = store.state.tapPowerBoostMultiplier || 1
        const tokensEarned = baseTokens * boostMultiplier
        floatingNumbers.value.push({ 
          id: Date.now(), 
          x, 
          y, 
          amount: tokensEarned
        })

        store.commit('incrementLocalBalance', tokensEarned)
        store.commit('decrementAvailableTaps', tokensEarned)

        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium')
        }

        setTimeout(() => {
          isClicked.value = false
        }, 100)

        setTimeout(() => {
          floatingNumbers.value = floatingNumbers.value.filter(num => num.id !== floatingNumbers.value[0].id)
        }, 1000)

        clearTimeout(tapTimeout.value)
        tapTimeout.value = setTimeout(() => {
          store.dispatch('sendTaps')
        }, 1000)
      }
    }

    onMounted(() => {
      store.dispatch('loadUserData')
      updateInterval.value = setInterval(() => {
        store.dispatch('updateTaps')
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(updateInterval.value)
      clearTimeout(tapTimeout.value)
    })

    return {
      isClicked,
      floatingNumbers,
      handleClick,
      showBoosts,
      availableTaps,
      availableMax,
    }
  }
}
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
  touch-action: none;
}

.game-container {
  min-height: 100vh;
  width: 100vw;
  background-image: url('@/static/background.png');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 50px;
}

.game-area {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: -1rem;
}

.skuff-container {
  position: relative;
  width: 400px;
  height: 400px;
  margin-top: 40%; /* Увеличиваем отступ сверху еще на 20px */
}

@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only 
screen and (min-device-pixel-ratio : 1.5) { /* для яблок */
  .skuff-container {
    margin-top: calc(100vh - 550px);
  }
}

.skuff-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.click-area-wrapper {
  position: absolute;
  top: 67%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 210px;
  height: 140px;
}

.glow-effect {
  position: absolute;
  top: -40px;
  left: -40px;
  right: -40px;
  bottom: -40px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,0,0.5) 0%, rgba(255,255,0,0) 60%);
  animation: glow 2s infinite alternate;
  pointer-events: none;
  z-index: 1;
  transition: background 0.3s ease;
}

.click-area {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
  background-image: url('@/static/puzo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.1s ease-in-out;
  z-index: 2;
}

.puzo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.click-area.clicked {
  transform: scale(0.95);
}

.click-area.clicked + .glow-effect {
  background: radial-gradient(circle, rgba(255,255,0,0.7) 0%, rgba(0, 255, 0, 0) 70%);
}

@keyframes glow {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}

.progress-boost {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 1rem;
  z-index: 1001;
}

.progress-text {
  background-color: #af4cac;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.boost-button {
  background-color: #4CAF50;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.boost-button:hover {
  background-color: #45a049;
}

.floating-number {
  position: absolute;
  animation: float 1s ease forwards;
  color: #ffdd57;
  font-size: 1.5rem;
  font-weight: bold;
  transform: translate(-50%, -50%);
}

@keyframes float {
  0% {
    transform: translate(-50%, -50%) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) translateY(-30px);
    opacity: 0;
  }
}

</style>