<template>
  <div class="rating-container">
    <div class="rating-content">
      <div class="total-skuffs">
        <div class="skuffs-label">Total Skuffs:</div>
        <div class="skuffs-value">{{ usersCount }}</div>
      </div>

      <div class="tab-buttons">
        <button
          :class="['tab-button', { active: activeTab === 'Total' }]"
          @click="setActiveTab('Total')"
        >
          Total
        </button>
        <button
          :class="['tab-button', { active: activeTab === 'Frens' }]"
          @click="setActiveTab('Frens')"
        >
          Frens
        </button>
      </div>

      <div class="current-user">
        <div class="user-info">
          <span class="user-position">{{ userRating }}</span>
          <span class="user-name">{{ userData.username }}</span>
        </div>
        <span class="user-balance">{{ getUserValue }}</span>
      </div>

      <div class="divider"></div>

      <div class="users-list">
        <div v-for="(user, index) in topUsers" :key="user.user_id" class="user-entry">
          <div class="user-info">
            <Trophy v-if="index < 3" :class="['trophy', getTrophyColor(index)]" />
            <span v-else class="user-position">{{ index + 1 }}</span>
            <span class="user-name">{{ user.username }}</span>
          </div>
          <span class="user-balance">{{ user.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { Trophy } from 'lucide-vue-next'

export default {
  name: 'Rating',
  components: {
    Trophy
  },
  setup() {
    const store = useStore()
    const activeTab = ref('Total')

    const usersCount = computed(() => store.getters.getUsersCount)
    const totalSkuffs = computed(() => store.state.totalSkuffs)
    const userRating = computed(() => store.state.userRating)
    const topUsers = computed(() => store.state.topUsers)
    const userData = computed(() => store.state.userData)

    const getUserValue = computed(() => {
      if (activeTab.value === 'Total') {
        return userData.value ? userData.value.total_earned : 0
      } else {
        return userData.value ? userData.value.ref_count : 0
      }
    })

    const setActiveTab = async (tab) => {
      activeTab.value = tab
      await loadRatingData()
    }

    const getTrophyColor = (index) => {
      const colors = ["trophy-gold", "trophy-silver", "trophy-bronze"]
      return colors[index] || ""
    }

    const loadRatingData = async () => {
      const ratingType = activeTab.value === 'Total' ? 'total_earned' : 'ref_count'
      await store.dispatch('loadRatingData', ratingType)
    }

    onMounted(() => {
      store.dispatch('fetchUsersCount')
      loadRatingData()
    })

    watch(activeTab, loadRatingData)

    return {
      activeTab,
      usersCount,
      totalSkuffs,
      userRating,
      topUsers,
      userData,
      getUserValue,
      setActiveTab,
      getTrophyColor
    }
  }
}
</script>

<style scoped>
.rating-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #c9aa71;
  padding: 1rem;
  color: black;
}

.rating-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.total-skuffs {
  background-color: #4CAF50;
  color: white;
  padding: 0.5rem; /* Уменьшено с 0.75rem */
  border-radius: 0.5rem;
  text-align: center;
  margin-bottom: 0.75rem; /* Уменьшено с 1rem */
}

.skuffs-label {
  font-size: 0.875rem; /* Уменьшено с 1rem */
}

.skuffs-value {
  font-size: 1.25rem; /* Уменьшено с 1.5rem */
  font-weight: bold;
}

.tab-buttons {
  display: flex;
  margin-bottom: 0.75rem;
}

.tab-button {
  background: none;
  border: none;
  color: #ccc;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 0.1rem; /* Умеьшено в 2 раза с 0.5rem */
}

.tab-button.active {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

.current-user, .user-entry {
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.current-user {
  background-color: #4CAF50;
  color: white;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.user-position, .trophy {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.user-name, .user-balance {
  font-size: 1.125rem;
}

.divider {
  height: 1px;
  background-color: white;
  margin: 0.75rem 0; /* Уменьшено с 1rem */
}

.users-list {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
  max-height: calc(13 * (0.75rem * 2 + 1rem)); /* Высота примерно 7 карточек */
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none; /* Для Internet Explorer и Edge */
}

.users-list::-webkit-scrollbar {
  display: none; /* Для Chrome, Safari и Opera */
}

.trophy-gold {
  color: #ffd700;
}

.trophy-silver {
  color: #c0c0c0;
}

.trophy-bronze {
  color: #cd7f32;
}

</style>