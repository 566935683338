export const globalSettings = {
    domain: "ff-crypto.com",
    adsgramId: "4015",
    telegramBot: "skufftokenbot",
	mobileOnly: false,
    items: [
        { id: 1, name: "Item 1" },
        { id: 2, name: "Item 2" },
        { id: 3, name: "Item 3" },
    ],
};