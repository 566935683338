<template>
  <header class="header">
    <div class="header-content">
      <div class="user-info">
        <div class="avatar-container">
          <div class="user-avatar">{{ usernameInitial }}</div>
        </div>
        <div class="user-details">
          <h1 class="username">{{ username }} <span class="badge" v-if="badge">{{ badge }}</span></h1>
          <div class="level-info">
            LEVEL {{ level }} • {{ rank }}
          </div>
          <div class="level-progress">
            <div class="progress-bar" :style="{ width: levelProgressPercentage + '%' }"></div>
          </div>
        </div>
      </div>
      <div class="balance-info">
        <div class="balance-value">
          <span v-if="page == 'tasks'">
            SKUF {{ formattedBalance }}
            <span class="usdt" ><br>USDT {{ formattedBalanceUsdt }}</span>
          </span>
          <span v-else>
            {{ formattedBalance }}
          </span>
        </div>
        <div class="token-rates" style="display: none">
          <span>t/h: {{ tokensPerHour }}</span>
          <span>t/tap: {{ tokensPerTap }}</span>
          <span v-if="formattedBoostTime > 0">bst: {{ formattedBoostTime }}</span>
        </div>
      </div>
    </div>
    <div class="boost-timer">
      <span v-if="formattedBoostTime > 0">BOOST {{ formattedBoostTime }}s</span>
    </div>
  </header>
</template>

<script>
import { computed, ref, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Head',
  props: {
    page: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore()
    const remainingBoostTime = ref(0)

    const username = computed(() => store.state.userData ? store.state.userData.username : 'User')
    const badge = computed(() => store.state.userData ? store.state.userData.badge : '')
    const usernameInitial = computed(() => username.value.charAt(0).toUpperCase())
    const usdt_balance = computed(() => store.state.userData ? store.state.userData.usdt_balance : 1)
    const balance = computed(() => store.getters.getBalance)
    const level = computed(() => store.state.userData ? store.state.userData.level : 1)
    const totalEarned = computed(() => store.state.userData ? store.state.userData.total_earned : 0)

    const formattedBalanceUsdt = computed(() => usdt_balance.value.toString())
    const formattedBalance = computed(() => balance.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))

    const levelProgressPercentage = computed(() => {
      const currentLevelThreshold = 1000 * Math.pow(2.5, level.value - 1)
      const nextLevelThreshold = 1000 * Math.pow(2.5, level.value)
      const progress = ((totalEarned.value - currentLevelThreshold) / (nextLevelThreshold - currentLevelThreshold)) * 100
      return Math.min(Math.max(progress, 0), 100)
    })

    // Следим за изменением процента прогресса
    watch(levelProgressPercentage, (newValue) => {
      if (newValue >= 100) {
        store.commit('incrementLevel')
        store.dispatch('updateLevel')
      }
    })

    const rank = computed(() => {
      if (level.value < 5) return 'Rookie'
      if (level.value < 10) return 'Pro'
      if (level.value < 20) return 'Expert'
      return 'Master'
    })

    const updateRemainingBoostTime = () => {
      const now = Date.now()
      const endTime = store.state.tapPowerBoostEndTime
      if (endTime && endTime > now) {
        remainingBoostTime.value = Math.ceil((endTime - now) / 1000)
      } else {
        remainingBoostTime.value = 0
      }
    }

    let intervalId

    onMounted(() => {
      updateRemainingBoostTime()
      intervalId = setInterval(updateRemainingBoostTime, 1000)
    })

    onUnmounted(() => {
      clearInterval(intervalId)
    })

    const formattedBoostTime = computed(() => {
      if (remainingBoostTime.value <= 0) return 0
      return remainingBoostTime.value % 60
    })

    return {
      username,
      usernameInitial,
      formattedBalanceUsdt,
      formattedBalance,
      level,
      rank,
      levelProgressPercentage,
      tokensPerHour: computed(() => store.state.userData ? store.state.userData.token_per_hour : 0),
      tokensPerTap: computed(() => {
        return (store.state.userData ? store.state.userData.token_per_tap : 1) * (store.state.tapPowerBoostMultiplier ? store.state.tapPowerBoostMultiplier : 1)
      }),
      formattedBoostTime,
      remainingBoostTime,
      badge,
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'PatsySans';
  src: url('~@/static/PatsySans.otf') format('otf'); /* Убедитесь, что путь к файлу правильный */
}

.header {
  position: fixed;
  top: 10px; /* Добавляем отступ сверху */
  left: 10px; /* Добавляем отступ слева */
  right: 10px; /* Добавляем отступ справа */
  width: calc(100% - 20px); /* Уменьшаем ширину на 20px для отступов */
  background-color: #4CAF50;
  color: white;
  padding: 0.75rem;
  z-index: 1000;
  box-sizing: border-box;
  border-radius: 10px; /* Закругляем края */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.avatar-container {
  width: 2rem;
  height: 2rem;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.user-avatar {
  font-size: 1rem;
  font-weight: bold;
  color: #4CAF50;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.username {
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
}

.level-info {
  font-size: 0.75rem;
}

.level-progress {
  width: 6rem;
  height: 0.25rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0.125rem;
  overflow: hidden;
  margin-top: 0.25rem;
}

.progress-bar {
  height: 100%;
  background-color: white;
  transition: width 0.3s ease;
}

.balance-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.balance-value {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  text-align: center;
  letter-spacing: 3px;
  font-family: 'PatsySans', sans-serif;
}
.balance-value .usdt {
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  color: #cbcbcb;
}

.token-rates {
  font-size: 0.75rem;
  opacity: 0.8;
}

.token-rates span {
  margin-left: 0.5rem;
}

.boost-timer {
  display: inline-block;
    position: absolute;
    margin-left: -3.75rem;
    margin-top: 2rem;
    font-weight: 800;
    text-shadow: 0 0 BLACK;
    background: #4caf50;
    border-radius: 6px;
    padding: 0 12px;
}

span.badge {
    background: #ffffff;
    padding: 2px 2px;
    margin: 0px 4px;
    border-radius: 5px;
    color: #4caf50;
    font-size: 12px;
    line-height: 17px;
}
</style>
