<template>
  <div class="tasks-container">
    <Head :page="'tasks'" />
    <SimpleNotification :visible="showNotification" :text="'You received $1000 SCUF and 0.0005 USDT!'" @update:visible="showNotification = $event" />
    <div class="tasks-content">
      <div class="tab-buttons">
        <button
          v-for="tab in ['Tasks', 'Quests', 'Frens']"
          :key="tab"
          :class="['tab-button', { active: activeTab === tab }]"
          @click="setActiveTab(tab)"
        >
          {{ tab }}
        </button>
      </div>

      <div v-if="loading" class="loading-message">Loading tasks...</div>
      <div v-else-if="error" class="error-message">{{ error }}</div>
      <div v-else-if="sortedTasks.length === 0" class="no-tasks-message">No tasks available</div>
      <div v-else class="tasks-list">
        <div v-for="task in sortedTasks" :key="task.task_id" class="task-card">
          <div class="task-info">
            <component :is="getIconComponent(task.icon)" class="task-icon" />
            <div class="task-details">
              <div class="task-title">{{ task.title }}</div>
              <div class="task-reward">
                {{ getRewardText(task) }}
              </div>
              <div v-if="task.type === 'Frens' || task.type === 'ADS' || task.type === 'Boosts'" class="task-progress">
                {{ getTaskProgress(task) }} / {{ task.type === 'Frens' ? task.required_refs : (task.type === 'ADS' ? task.required_ads : task.required_boosts) }}
              </div>
            </div>
          </div>
          <button 
            v-if="!isTaskCompleted(task)"
            :class="['task-button', { 
              'loading': task.status === 'Checking',
              'check': task.status === 'Check',
              'disabled': isTaskDisabled(task)
            }, {'ads': task.type === 'ADS'}]"
            @click="handleTaskAction(task)"
            :disabled="task.status === 'Checking' || isTaskDisabled(task)"
          >
            <span v-if="task.status !== 'Checking'">{{ getTaskButtonText(task) }}</span>
            <div v-else class="loading-spinner"></div>
          </button>
          <div v-else class="task-completed">Completed</div>
        </div>
      </div>
    </div>
    <div v-if="showPopup" class="popup">
      <p class="captcha-name">
        CAPTCHA
        <span style="float: right;" @click="cancelVerification">x</span>
      </p>
      <p>{{question}}</p>
      <div class="button-container">
        <button v-for="number in numbers" :key="number" @click="verifyAd(number)" :class="[{'wrong': !number || number == 0 || number == '-'}]">
          {{ number }}
        </button>
      </div>
      <p>Attempts: {{ attemptCount }} / 3</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore, mapGetters } from 'vuex'
import Head from './Head.vue'
import SimpleNotification from './SimpleNotification.vue'
import { PlayCircle, Trophy, Rocket, Send, Gift, Coins, Smile } from 'lucide-vue-next'
import axios from 'axios'
import { globalSettings } from '../../globalSettings';

export default {
  name: 'Tasks',
  components: {
    Head,
    SimpleNotification,
    PlayCircle,
    Trophy,
    Rocket,
    Send,
    Gift,
    Coins,
    Smile
  },
  data() {
      return {
        globalSettings: globalSettings, // Сохраняем данные в локальном состоянии компонента
      };
  },
  computed: {
    ...mapGetters(['getWatchedAds']), // Получаем watchedAds из Vuex
    watchedAds() {
        return this.getWatchedAds; // Возвращаем watchedAds
    },
  },
  setup() {
    const store = useStore()
    const activeTab = ref('Tasks')
    const tasks = ref([])
    const loading = ref(true)
    const error = ref(null)
    const showPopup = ref(false)
    const numbers = ref([0,0,0,0,0,0,0,0,0]);
    const question = ref('0 x 0');
    const attemptCount = ref(0);
    const showNotification = ref(false);

    const loadTasks = async () => {
      try {
        loading.value = true
        error.value = null
        const response = await axios.get('/tasks', {
          params: { user_id: store.state.userId }
        })
        tasks.value = response.data.tasks.map(task => ({
          ...task,
          status: task.completed ? 'Completed' : 'Start'
        }))
        console.log('Loaded tasks:', tasks.value) // Добавим для отладки
      } catch (err) {
        console.error('Ошибка при загрузке задач:', err)
        error.value = 'Не удалось загрузить задачи. Пожалуйста, попробуйте позже.'
      } finally {
        loading.value = false
      }
    }

    onMounted(loadTasks)

    watch(activeTab, () => {
      console.log('Active tab changed:', activeTab.value) // Добавим для отладки
    })

    const sortedTasks = computed(() => {
      console.log('Sorting tasks for tab:', activeTab.value)
      return tasks.value
        .filter(task => {
          if (activeTab.value === 'Tasks') {
            return task.type === 'Tasks' || task.type === 'ADS' || task.type === 'Boosts';
          }
          return task.type.toLowerCase() === activeTab.value.toLowerCase();
        })
        .sort((a, b) => {
          if (a.completed === b.completed) return 0;
          return a.completed ? 1 : -1;
        });
    });

    const setActiveTab = (tab) => {
      activeTab.value = tab
    }

    const isTaskCompleted = (task) => {
      if (task.type === 'ADS') {
        if (task.title === 'Watch the Full AD') {
          return (task.progress || 0) >= task.required_ads;
        }
        return store.state.watchedAds >= task.required_ads;
      }
      return task.completed;
    };

    const isTaskDisabled = (task) => {
      if (task.type === 'Frens') {
        return store.state.refCount < task.required_refs;
      }
      if (task.type === 'Boosts') {
        return store.state.boostsCount < task.required_boosts;
      }
      if (task.title === 'Ads watching hero (300 ads done)') {
        const watchFullAdTask = tasks.value.find(t => t.title === 'Watch the Full AD');
        return !watchFullAdTask || getTaskProgress(watchFullAdTask) < watchFullAdTask.required_ads;
      }
      return false;
    };

    const getTaskButtonText = (task) => {
      if (task.type === 'ADS') {
        return 'Watch';
      }
      return task.status === 'Start' ? 'Start' : 'Check';
    };

    const showAd = () => {
      return new Promise((resolve, reject) => {
        if (window.Adsgram) {
          const AdController = window.Adsgram.init({ blockId: globalSettings.adsgramId });
          AdController.show()
            .then((result) => {
              console.log('Реклама просмотрена', result);
              resolve(true);
            })
            .catch((result) => {
              console.error('Ошибка показа рекламы', result);
              reject(false);
            });
        } else {
          console.error('Adsgram не инициализирован');
          reject(false);
        }
      });
    };

    const handleTaskAction = async (task) => {
      if (isTaskCompleted(task) || isTaskDisabled(task)) return;

      if (task.type === 'ADS') {
        try {
          const watchedAdsCount = store.state.watchedAds;
          if (watchedAdsCount % 5 === 0 && watchedAdsCount > 0) {
            await fetchNumbers();
            showPopup.value = true; // Показываем всплывающее окно
          } else {
            showNotification.value = false
            await watchAd();
          }
        } catch (error) {
          console.error('Ошибка при показе рекламы:', error);
          return;
        }
      } else if (task.status === 'Start') {
        if (task.link) {
          window.open(task.link, '_blank');
        }
        task.status = 'Check';
      } else if (task.status === 'Check') {
        task.status = 'Checking';
        try {
          const response = await axios.post('/complete_task', {
            user_id: store.state.userId,
            task_id: task.task_id
          });
          if (response.data.success) {
            task.completed = true;
            task.status = 'Completed';
            store.commit('updateUserData', response.data.user);
            store.commit('setLocalBalance', response.data.user.balance);
          } else {
            task.status = 'Check';
          }
        } catch (error) {
          console.error('Ошибка при выполнении задачи:', error)
          task.status = 'Check'
        }
      }
    }

    const getIconComponent = (iconName) => {
      const iconMap = {
        PlayCircle, Trophy, Rocket, Send, Gift, Coins, Smile
      }
      return iconMap[iconName] || Send
    }

    const getRewardText = (task) => {
      if (task.title === 'Watch the Full AD') return `+${task.reward_tokens} SKUFF and free USDT`
      let rewardText = ''
      if (task.reward_tokens) rewardText += `+${task.reward_tokens} `
      if (task.reward_hour) rewardText += `+${task.reward_hour}/час `
      if (task.reward_tap) rewardText += `+${task.reward_tap}/клик `
      return rewardText.trim()
    }

    const getTaskProgress = (task) => {
      if (task.type === 'ADS') {
        if (task.title === 'Watch the Full AD') {
          return task.progress || 0;
        }
        return store.state.watchedAds;
      } else if (task.type === 'Frens') {
        return store.state.refCount;
      } else if (task.type === 'Boosts') {
        return store.state.boostsCount;
      }
      return 0;
    }

    
    const watchAd = async () => {
      // Логика для просмотра рекламы
      const response = await axios.post('/request_ad', {
        user_id: store.state.userId
      });
      const adWatched = await showAd();
      if (adWatched) {
          store.commit('incrementWatchedAds');
          tasks.value = tasks.value.map(task => task.title == "Watch the Full AD" ? {...task, progress: task.progress + 1} : task)
          // Добавляем обращение к эндпоинту для начисления монет
          try {
            const response = await axios.post('/reward_ad_view', {
              user_id: store.state.userId,
              reward_amount: 1000
            });
            if (response.data.success) {
              store.commit('updateUserData', response.data.user);
              store.commit('setLocalBalance', response.data.user.balance);
              store.commit('setLocalUsdtBalance', response.data.wallet.usdt_balance);
              showNotification.value = true; // Показываем уведомление при успешном запросе
            }
          } catch (error) {
            console.error('Ошибка при начислении награды за просмотр рекламы:', error);
          }
        //task.status = 'Check';
      }
    }

    const verifyAd = async (number) => {
      try {
        const response = await axios.post('/captcha', {
          user_id: store.state.userId,
          number: number
        }); // Отправляем запрос на эндпоинт
        if (response.data.success) {
          showPopup.value = false; // Закрываем всплывающее окно
          await watchAd(); // Продолжаем просмотр рекламы
        } else {
          attemptCount.value += 1;
          numbers.value = numbers.value.map(a => a == number ? '-' : a);
          if (attemptCount.value == 3) {
            showPopup.value = false; // Закрываем всплывающее окно
            attemptCount.value = 0;
          }
        }
      } catch (error) {
        console.error('Ошибка при проверке:', error);
      }
    }

    const cancelVerification = () => {
      showPopup.value = false; // Закрываем всплывающее окно
    }


    const fetchNumbers = async () => {
      try {
        const response = await axios.get('/captcha', {
          params: { user_id: store.state.userId }
        }); // Получаем данные с бэка
        const data = JSON.parse(atob(response.data.data)); // Декодируем base64
        numbers.value = data.random_numbers.sort(); // Получаем случайные числа
        question.value = data.key_string; // Получаем случайные числа
      } catch (error) {
        console.error('Ошибка при получении чисел:', error);
      }
    };

    return {
      activeTab,
      sortedTasks,
      setActiveTab,
      handleTaskAction,
      getIconComponent,
      getRewardText,
      loading,
      error,
      store,
      isTaskCompleted,
      isTaskDisabled,
      getTaskButtonText,
      getTaskProgress,
      showAd,
      
      showPopup,
      watchAd,
      verifyAd,
      cancelVerification,
      numbers,
      question,
      attemptCount,
      showNotification,
    }
  }
}
</script>

<style scoped>
.tasks-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #c9aa71;
  color: black;
}

.tasks-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 90px; /* Отступ для шапки */
  padding-bottom: 60px; /* Отступ для нижнего меню */
}

.tab-buttons {
  display: flex;
  background-color: #4b5563;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.tab-button {
  flex: 1;
  padding: 0.5rem;
  text-align: center;
  color: #d1d5db;
  background: none;
  border: none;
  cursor: pointer;
}

.tab-button.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 0.5rem;
}

.tasks-list {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none; /* Для Internet Explorer и Edge */
  margin-bottom: 1rem;
  max-height: calc(100vh - 220px); /* Высота с учетом отступов */
}

.tasks-list::-webkit-scrollbar {
  display: none; /* Для Chrome, Safari и Opera */
}

.task-card {
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.task-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #4CAF50;
  flex-shrink: 0;
}

.task-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.task-title {
  font-size: 0.875rem;
  font-weight: 600;
}

.task-reward {
  font-size: 0.75rem;
  color: #6b7280;
}

.task-limit {
  color: #4CAF50;
}

.task-button {
  width: 80px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.task-button:not(.loading):not(.check):not(.disabled) {
  background-color: #4CAF50;
  color: white;
}

.task-button.loading {
  position: relative;
  color: transparent;
  background-color: #4CAF50;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.task-button:hover {
  background-color: #45a049;
}

.task-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.task-button.loading:hover {
  background-color: #45a049;
}

.task-button.loading:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.task-button.check:hover {
  background-color: #FF8C00;
}

.task-button.check:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.task-completed {
  color: #4CAF50;
  font-weight: bold;
  font-size: 14px;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid #4CAF50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.task-progress {
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 0.1rem;
}

.task-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.task-button.disabled:hover {
  background-color: #cccccc;
}

.task-button.start, .task-button.check.ads, .task-button.check.ads:hover {
  background-color: #4CAF50;
  color: white;
}

.task-button.check:not(.ads) {
  background-color: #FFA500;
  color: white;
}


.popup {
  /* Стили для всплывающего окна */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  border-radius: 6px;
}
@media only screen and (max-width: 600px) { /* для яблок */
  .popup {
    left: 33%;
    transform: translate(-25%, -50%);
  }
}
.captcha-name {
  background-color: rgb(76, 175, 80);
  color: white;
  margin: 0;
    padding: 1em;
    border-radius: 6px;
}
button {
  background-color: rgb(229, 231, 235);
  border-radius: 6px;
  height: 3rem;
  width: 4rem;
    margin: 4px;
    cursor: pointer;
    border: 0;
}
button:hover{
  background-color: rgb(219, 221, 225);
}
button.wrong {
  background-color: #ffc6c6;
}
</style>
