<template>
  <div id="app">
    <LoadingScreen v-if="isLoading" @loading-complete="onLoadingComplete" />
    <template v-else>
      <NavBar v-if="isUserLoaded" />
      <router-view v-if="isUserLoaded" />
      <div v-else-if="error">Error: {{ error }}</div>
    </template>
    <!-- Добавляем скрытый элемент для TON Connect -->
    <div id="ton-connect" style="display: none;"></div>
    <template v-if="isDesktop"><div class="note">Play on your mobile</div><div class="qrElement"></div></template>
    <div class="desktop-screen"></div>

    <!-- Попап с приветствием -->
    <div v-if="showWelcomePopup && isUserLoaded" class="welcome-popup">
      <div class="popup-content">
        <span style="float: right;" @click="showWelcomePopup = false">x</span>
        <h4>Hey, Skuff! 🖐 Welcome to the Empire!</h4>
        <p>Get right to the good stuff—earn <b>USDT</b> just by completing tasks in the “Tasks” section. 🤑</p>
        <p>Prove to everyone that even Skuffs can make big moves! Get started now! 💥</p>
        <button @click="goToTasks">Tasks</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import LoadingScreen from './components/LoadingScreen.vue'
import NavBar from './components/NavBar.vue'
import { TonConnectUI } from '@tonconnect/ui';
import { toUserFriendlyAddress } from '@tonconnect/sdk';
import { globalSettings } from '../globalSettings';
import { useRouter } from 'vue-router'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    LoadingScreen,
    NavBar
  },
  data() {
      return {
        globalSettings: globalSettings, // Сохраняем данные в локальном состоянии компонента
      };
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const isUserLoaded = ref(false)
    const isLoading = ref(true)
    const isDesktop = ref(false)
    const error = ref(null)
    const showWelcomePopup = ref(true) // Состояние для попапа
    let updateInterval
    let tonConnector

    const initializeTonConnect = () => {
      const tonConnectUI = new TON_CONNECT_UI.TonConnectUI({
        manifestUrl: 'https://ff-crypto.com/tonconnect-manifest.json',
        buttonRootId: 'ton-connect'
      });

      // Подписываемся на изменения статуса подключения
      tonConnectUI.onStatusChange(walletInfo => {
        if (walletInfo) {
          console.log('Кошелек подключен:', walletInfo);
          store.commit('setWalletInfo', walletInfo);
          store.dispatch('saveWalletAddress', toUserFriendlyAddress(walletInfo.account.address));
        } else {
          console.log('Кошелек отключен');
          store.commit('setWalletInfo', null);
        }
      });

      // Сохраняем экземпляр connector в хранилище для использования в других компонентах
      store.commit('setTonConnector', tonConnectUI);
    };

    const preloadImages = async () => {
      const images = [
        require('@/static/background.png'),
        require('@/static/skuff.png'),
        require('@/static/qrcode.png'),
        require('@/static/puzo.png')
      ]
      
      await Promise.all(images.map(src => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = src
          img.onload = resolve
          img.onerror = reject
        })
      }))
    }

    const onLoadingComplete = () => {
      isLoading.value = false
    }

    const initializeApp = async () => {
      let userId;
      try {
        if (window.Telegram && window.Telegram.WebApp) {
          console.log('Telegram WebApp обнаружен');
          window.Telegram.WebApp.ready();
          window.Telegram.WebApp.expand();
          const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe || {};
          userId = initDataUnsafe.user ? initDataUnsafe.user.id : null;
        } else {
          console.log('Telegram WebApp не обнаружен, используем URL параметры');
          const urlParams = new URLSearchParams(window.location.search);
          userId = urlParams.get('user_id');
        }

        console.log('User ID:', userId);
        if (userId) {
          store.commit('setUserId', userId);
          await store.dispatch('loadUserData');
          
          await axios.post('/analytics', {
            route: "Loading screen",
            user_id: userId
          });
          
          // Инициализация Adsgram
          if (window.Adsgram) {
            const AdController = window.Adsgram.init({ blockId: globalSettings.adsgramId });
            store.commit('setAdController', AdController);
          } else {
            console.error('Adsgram SDK не загружен');
          }

          // Инициализация TON Connect
          initializeTonConnect();

          await preloadImages();
          
          isUserLoaded.value = true;
          
          await axios.post('/analytics', {
            route: "Home",
            user_id: userId
          });
        } else {
          error.value = 'ID пользователя не найден';
        }
      } catch (e) {
        console.error('Ошибка при инициализации:', e);
        error.value = 'Ошибка при инициализации приложения';
      }
    }

    const goToTasks = () => {
      showWelcomePopup.value = false; // Скрываем попап
      router.push({ name: 'Tasks' });
    }

    onMounted(async () => {
      // Проверка на мобильное устройство
      const checkMobile = globalSettings.onlyMobile
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      if (checkMobile && !isMobile) {
        isLoading.value = false
        isDesktop.value = true
        return;
      }
      await initializeApp();
      // Устанавливаем таймаут на 4 секунды для имитации минимального времени загрузки
      setTimeout(() => {
        isLoading.value = false;
      }, 4000);
    })

    onMounted(() => {
      updateInterval = setInterval(() => {
        store.dispatch('updateAvailableTaps')
      }, 1000) // Обновляем каждую секунду
    })

    onUnmounted(() => {
      clearInterval(updateInterval)
    })

    return {
      isUserLoaded,
      isLoading,
      isDesktop,
      error,
      onLoadingComplete,
      
      showWelcomePopup, // Возвращаем состояние попапа
      goToTasks // Возвращаем функцию для перехода на страницу с заданиями
    }
  }
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none; /* Запрещаем выделение элементов */
  touch-action: none; /* Запрещаем зум и скролл */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.desktop-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/static/background.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -9999;
}

.note {
  font-size: 2em;
  color: #ffffff;
  transform: translate(0, 30%);
  text-transform: capitalize;
}

.qrElement {
  position: fixed;
  top: 0;
  left: 0;
  width: 400px; /* Ширина QR-кода */
  height: 400px; /* Высота QR-кода */
  background-image: url('@/static/qrcode.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  z-index: 9999;
  /* Добавляем следующие стили для центрирования */
  transform: translate(-50%, -50%); /* Сдвигаем элемент на 50% его ширины и высоты */
  top: 50%; /* Устанавливаем верхнюю границу на 50% высоты экрана */
  left: 50%; /* Устанавливаем левую границу на 50% ширины экрана */
}
.welcome-popup {  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 30%;
  transform: translate(-22%, -50%);
  background-color: white;
  border: 2px solid #4CAF50;
  border-radius: 10px;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content h2 {
  margin: 0 0 10px;
}

.popup-content p {
  margin: 0 0 20px;
}

.popup-content button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  margin: auto;
  width: 80%;
}

.popup-content button:hover {
  background-color: #45a049;
}
</style>
