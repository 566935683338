import { createRouter, createWebHistory } from 'vue-router'
import SkuffGame from '../components/SkuffGame.vue'
import Upgrade from '../components/Upgrade.vue'
import Frens from '../components/Frens.vue'
import Tasks from '../components/Tasks.vue'
import Rating from '../components/Rating.vue'
import Wallet from '../components/Wallet.vue'

const routes = [
  {
    path: '/',
    name: 'SkuffGame',
    component: SkuffGame
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: Upgrade
  },
  {
    path: '/frens',
    name: 'Frens',
    component: Frens
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/rating',
    name: 'Rating',
    component: Rating
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
