import { createStore } from 'vuex'
import axios from 'axios'
import { updateUserLevel } from '@/api/database'
import TonConnect from '@tonconnect/sdk'

export default createStore({
  state: {
    userId: null,
    userData: null,
    watchedAds: 0,
    refCount: 0,
    boostsCount: 0,
    localBalance: 0,
    localTaps: 0,
    availableTaps: 0,
    availableMax: 100, // Добавляем availableMax в состояние
    friends: [],
    referralCode: null,
    totalSkuffs: 0,
    userRating: 0,
    topUsers: [],
    usersCount: 0,
    level: 1,
    tapPowerBoostEndTime: null,
    lastTapPowerBoostUse: null,
    lastEnergyRefillBoostUse: null,
    walletInfo: null,
    tonConnector: null,
    tapPowerBoostMultiplier: 1,
    tapPowerBoostEndTime: null,
  },
  mutations: {
    setUserId(state, userId) {
      state.userId = userId
    },
    setUserData(state, userData) {
      state.userData = userData
      state.watchedAds = userData.watched_ads
      state.refCount = userData.ref_count
      state.boostsCount = userData.boosts_used
      state.localBalance = userData.balance
      state.availableTaps = userData.available_taps
      state.availableMax = userData.available_max 
      state.referralCode = userData.referal_code
    },
    incrementLocalBalance(state, amount) {
      state.localBalance += amount
      state.localTaps += amount
    },
    decrementAvailableTaps(state, amount) {
      state.availableTaps = Math.max(0, state.availableTaps - amount)
    },
    resetLocalTaps(state) {
      state.localTaps = 0
    },
    updateAvailableTaps(state) {
      if (state.userData) {
        const currentTime = Date.now() / 1000
        const elapsedTime = currentTime - state.userData.last_update_time
        const recoveredTaps = Math.floor(elapsedTime * state.userData.click_recover_per_sec)
        state.availableTaps = Math.min(state.availableMax, state.availableTaps + recoveredTaps)
        state.userData.last_update_time = currentTime
      }
    },
    setRefCount(state, count) {
      state.refCount = count
    },
    setFriends(state, friends) {
      state.friends = friends
    },
    setReferralCode(state, code) {
      state.referralCode = code
    },
    setRatingData(state, { total_skuffs, user_data, user_rating, value }) {
      state.totalSkuffs = total_skuffs
      state.userData = { ...state.userData, ...user_data }
      state.userRating = user_rating
    },
    setTopData(state, { top_users }) {
      state.topUsers = top_users
    },
    updateUserData(state, userData) {
      state.userData = { ...state.userData, ...userData }
      state.localBalance = userData.balance
      state.availableTaps = userData.availableTaps
    },
    setLocalBalance(state, balance) {
      state.localBalance = balance
    },
    setLocalUsdtBalance(state, balance) {
      state.userData.usdt_balance = balance
    },
    incrementLevel(state) {
      if (state.userData) {
        state.userData.level += 1
      }
    },
    decrementBalance(state, amount) {
      state.localBalance = Math.max(0, state.localBalance - amount)
      if (state.userData) {
        state.userData.balance = Math.max(0, state.userData.balance - amount)
      }
    },
    setAvailableTaps(state, value) {
      state.availableTaps = value
    },
    setAvailableMax(state, availableMax) {
      state.availableMax = availableMax
    },
    updateLocalBalance(state, amount) {
      state.balance += amount
      state.totalEarned += amount
    },
    setUpgrades(state, upgrades) {
      state.upgrades = upgrades
    },
    setUsersCount(state, count) {
      state.usersCount = count
    },
    incrementTokenPerTap(state, amount) {
      if (state.userData) {
        state.userData.token_per_tap += amount
      }
    },
    incrementClickRecoverPerSec(state, amount) {
      if (state.userData) {
        state.userData.click_recover_per_sec += amount
      }
    },
    incrementAvailableMax(state, amount) {
      if (state.userData) {
        state.userData.available_max += amount
        state.availableMax += amount
      }
    },
    incrementTokenPerHour(state, amount) {
      if (state.userData) {
        state.userData.token_per_hour += amount
      }
    },
    incrementWatchedAds(state) {
      state.watchedAds += 1;
    },
    setTasks(state, tasks) {
      state.tasks = tasks;
    },
    setTapPowerBoosts(state, value) {
      state.userData.tap_power_boosts = value
    },
    setEnergyRefillBoosts(state, value) {
      state.userData.energy_refill_boosts = value
    },
    setTapPowerBoostEndTime(state, value) {
      console.log(value)
      state.tapPowerBoostEndTime = value
    },
    setAvailableTaps(state, value) {
      state.availableTaps = value
    },
    addUsedBoostCount(state, value) {
      state.boostsCount += 1
    },
    setLastTapPowerBoostUse(state, time) {
      state.lastTapPowerBoostUse = time
    },
    setLastEnergyRefillBoostUse(state, time) {
      state.lastEnergyRefillBoostUse = time
    },
    updateBoostCooldowns(state) {
      const now = Date.now()
      if (state.lastTapPowerBoostUse) {
        const elapsedTime = (now - state.lastTapPowerBoostUse) / 1000
        if (elapsedTime >= 4 * 60 * 60) {
          state.lastTapPowerBoostUse = null
          if (state.userData.tap_power_boosts < 2) {
            state.userData.tap_power_boosts++
          }
        }
      }
      if (state.lastEnergyRefillBoostUse) {
        const elapsedTime = (now - state.lastEnergyRefillBoostUse) / 1000
        if (elapsedTime >= 4 * 60 * 60) {
          state.lastEnergyRefillBoostUse = null
          if (state.userData.energy_refill_boosts < 1) {
            state.userData.energy_refill_boosts++
          }
        }
      }
    },
    setWalletInfo(state, walletInfo) {
      state.walletInfo = walletInfo;
    },
    setTonConnector(state, connector) {
      state.tonConnector = connector;
    },
    setTapPowerBoostMultiplier(state, multiplier) {
      state.tapPowerBoostMultiplier = multiplier
    },
    setUserWalletAddress(state, address) {
      if (state.userData) {
        state.userData.wallet_address = address;
      }
    },
    setWalletData(state, data) {
      state.usdtBalance = data.usdt_balance;
      state.tonBalance = data.ton_balance;
    },
  },
  actions: {
    async loadUserData({ state, commit }) {
      try {
        const response = await axios.get(`/init?user_id=${state.userId}`)
        commit('setUserData', response.data)
        // Обновляем время последнего использования бустов, если они не были использованы
        if (response.data.tap_power_boosts === 2) {
          commit('setLastTapPowerBoostUse', null)
        }
        if (response.data.energy_refill_boosts === 1) {
          commit('setLastEnergyRefillBoostUse', null)
        }
        console.log("Loaded user data:", response.data)
      } catch (error) {
        console.error('Не удалось загрузить данные пользователя:', error)
        throw error
      }
    },
    async sendTaps({ state, commit }) {
      if (state.localTaps > 0) {
        try {
          await axios.post(`/user/${state.userId}/taps`, {
            taps: state.localTaps,
            availableTaps: state.availableTaps,
            timestamp: Date.now() / 1000
          })
          
          // Обновляем только локальные данные
          commit('updateLocalBalance', state.localTaps * state.tokenPerTap)
          commit('resetLocalTaps')
        } catch (error) {
          console.error('Ошибка при отправке тапов:', error)
        }
      }
    },
    updateTaps({ commit }) {
      commit('updateAvailableTaps')
    },
    async loadFriends({ state, commit }) {
      try {
        const response = await axios.get(`/user/${state.userId}/friends`)
        commit('setFriends', response.data)
      } catch (error) {
        console.error('Не удалось загрузить данные о друзьях:', error)
        throw error
      }
    },
    async loadRatingData({ state, commit }, ratingType = 'total_earned') {
      try {
        const response = await axios.get(`/user/${state.userId}/rating?user_id=${state.userId}&type=${ratingType}`)
        commit('setRatingData', response.data)
        const response2 = await axios.get(`/top_users?type=${ratingType}`)
        commit('setTopData', response2.data)
      } catch (error) {
        console.error('Не удалось загрузить данные рейтинга:', error)
        throw error
      }
    },
    async updateLevel({ commit, state, dispatch }) {
      if (state.userData) {
        const newLevel = state.userData.level + 1
        try {
          const response = await axios.put(`/user/${state.userId}/level/`, { level: newLevel })
          
          // Сохраняем текущее значене availableTaps
          const currentAvailableTaps = state.availableTaps
          
          // Обновляем данные пользователя
          commit('setUserData', response.data)
          
          // Устанавливаем availableTaps, сохраняя текущее значение
          commit('setAvailableTaps', currentAvailableTaps)
          
          // Обновляем availableMax
          commit('setAvailableMax', response.data.availableMax)
          
          // Обновляем все данные пользователя
          await dispatch('loadUserData')
        } catch (error) {
          console.error('Ошибка при обновлении уровня:', error)
        }
      }
    },
    async updateBalanceOnServer({ state }) {
      try {
        await axios.post(`/user/${state.userId}/update_balance`, {
          balance: state.localBalance
        })
      } catch (error) {
        console.error('Ошибка при обновлении баланса на сервере:', error)
      }
    },
    async loadTasks({ state, commit }) {
      try {
        const response = await axios.get(`tasks?user_id=${state.userId}`)
        commit('setTasks', response.data.tasks)
      } catch (error) {
        console.error('Не удалось загрузить задания:', error)
      }
    },
    async loadUpgrades({ state, commit }) {
      try {
        const response = await axios.get(`upgrades?user_id=${state.userId}`)
        commit('setUpgrades', response.data.upgrades)
      } catch (error) {
        console.error('Не удалось загрузить улучшения:', error)
      }
    },
    updateAvailableTaps({ state, commit }) {
      const now = Date.now()
      const elapsedTime = (now - state.lastUpdateTime) / 1000 // в секундах
      const increase = elapsedTime * (state.userData ? +state.userData.token_per_hour : 0) / 3600 // токены в секунду
      commit('incrementAvailableTaps', increase)
      commit('setLastUpdateTime', now)
    },
    async fetchUsersCount({ commit }) {
      try {
        const response = await axios.get('/users_count')
        commit('setUsersCount', response.data.users_count)
      } catch (error) {
        console.error('Ошибка при получении количества пользователей:', error)
      }
    },
    async confirmUpgrade({ state, commit }, upgrade) {
      try {
        const response = await axios.post('/upgrade', {
          user_id: state.userId,
          upgrade_id: upgrade.upgrade_id
        })
        // Обработка успешного ответа
        commit('setUserData', response.data.user)
        // ... другие действия после успешного обновления
      } catch (error) {
        console.error('Ошибка при подтверждении улучшения:', error)
        // Обработка ошибки
      }
    },
    async useBoost({ commit, state }, boostType) {
      try {
        const response = await axios.post('/use_boost', {
          user_id: state.userId,
          boost_type: boostType
        })

        if (response.data.success) {
          if (boostType === 'tap_power') {
            const boostDuration = 10 + response.data.boost_level
            commit('setTapPowerBoosts', response.data.remaining_boosts)
            commit('setTapPowerBoostEndTime', boostDuration)
            commit('setLastTapPowerBoostUse', Date.now())
            commit('setTapPowerBoostMultiplier', 1 + response.data.boost_power)
            setTimeout(() => {
              commit('setTapPowerBoostMultiplier', 1)
              commit('setTapPowerBoostEndTime', null)
            }, boostDuration * 1000)
          } else if (boostType === 'energy_refill') {
            commit('setEnergyRefillBoosts', response.data.remaining_boosts)
            commit('setAvailableTaps', response.data.available_taps)
            commit('setLastEnergyRefillBoostUse', Date.now())
          }
          commit('addUsedBoostCount')
        }
        return response.data
      } catch (error) {
        console.error('Ошибка при использовании буста:', error)
        throw error
      }
    },
    async addBoostUse({ commit, state }, boostType) {
      try {
        const response = await axios.post('/add_boost_use', {
          user_id: state.userId,
          boost_type: boostType
        })

        if (response.data.success) {
          if (boostType === 'tap_power') {
            commit('setTapPowerBoosts', response.data.remaining_boosts)
          } else if (boostType === 'energy_refill') {
            commit('setEnergyRefillBoosts', response.data.remaining_boosts)
          }
        }
        return response.data
      } catch (error) {
        console.error('Ошибка при добавлении использования буста:', error)
        throw error
      }
    },
    async loadBoostData({ commit, state }) {
      try {
        const response = await axios.get(`/boost_data?user_id=${state.userId}`)
        commit('setTapPowerBoosts', response.data.tap_power_boosts)
        commit('setEnergyRefillBoosts', response.data.energy_refill_boosts)
        commit('setLastTapPowerBoostUse', response.data.last_tap_power_boost_use)
        commit('setLastEnergyRefillBoostUse', response.data.last_energy_refill_boost_use)
      } catch (error) {
        console.error('Не удалось загрузить данные о бустах:', error)
      }
    },
    async connectWallet({ state, commit, dispatch }) {
      if (state.tonConnector) {
        try {
          await state.tonConnector.openModal();
          const walletInfo = await state.tonConnector.getWalletInfo();
          if (walletInfo) {
            commit('setWalletInfo', walletInfo);
            // Вызываем новое действие для сохранения адреса кошелька
            await dispatch('saveWalletAddress', walletInfo.account.address);
          }
        } catch (e) {
          console.error('Ошибка при открытии модального окна кошелька:', e);
          throw e;
        }
      } else {
        console.error('TON Connect не инициализирован');
        throw new Error('TON Connect не инициализирован');
      }
    },
    async disconnectWallet({ state, commit }) {
      if (state.tonConnector) {
        try {
          await state.tonConnector.disconnect();
          commit('setWalletInfo', null);
        } catch (e) {
          console.error('Ошибка при отключении кошелька:', e);
          throw e;
        }
      }
    },
    // Добавьте новое действие для сохранения адреса кошелька
    async saveWalletAddress({ state, commit }, address) {
      try {
        const response = await axios.post('/save_wallet_address', {
          user_id: state.userId,
          wallet_address: address
        });
        if (response.data.success) {
          commit('setUserWalletAddress', address);
        } else {
          console.error('Ошибка при сохранении адреса кошелька:', response.data.error);
        }
      } catch (error) {
        console.error('Ошибка при сохранении адреса кошелька:', error);
        throw error;
      }
    },
    async loadWalletData({ state, commit }) {
      try {
        const response = await axios.get(`/wallet_data?user_id=${state.userId}`);
        if (response.data.success) {
          commit('setWalletData', response.data);
          return response.data;
        } else {
          console.error('Ошибка при загрузке данных кошелька:', response.data.error);
          throw new Error(response.data.error);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных кошелька:', error);
        throw error;
      }
    },
    async requestWithdrawal({ state }) {
      try {
        const response = await axios.post('/request_withdrawal', {
          user_id: state.userId
        });
        return response.data;
      } catch (error) {
        console.error('Ошибка при запросе на вывод средств:', error);
        throw error;
      }
    },
    async replenishBoosts({ state }, boostType) {
      try {
        const response = await axios.post('/add_boost_use', {
          user_id: state.userId,
          boost_type: boostType
        });
        if (response.data.success) {
          // Обновляем состояние бустов в хранилище
          if (boostType === 'tap_power') {
            commit('setBoosts', { tapPowerBoosts: response.data.remaining_boosts });
          } else if (boostType === 'energy_refill') {
            commit('setBoosts', { energyRefillBoosts: response.data.remaining_boosts });
          }
        }
        return response.data;
      } catch (error) {
        console.error('Ошибка при восполнении бустов:', error);
        throw error;
      }
    },
  },
  getters: {
    canTap: (state) => state.availableTaps >= (state.userData ? state.userData.token_per_tap : 1),
    getBalance: (state) => state.localBalance,
    getAvailableTaps: (state) => Math.floor(state.availableTaps),
    getAvailableMax: (state) => state.availableMax,
    getRefCount: (state) => state.refCount,
    getFriends: (state) => state.friends,
    getReferralCode: (state) => state.referralCode,
    getTotalSkuffs: (state) => state.totalSkuffs,
    getUserRating: (state) => state.userRating,
    getTopUsers: (state) => state.topUsers,
    getUsersCount: (state) => state.usersCount,
    getTapPowerBoosts: (state) => state.userData ? state.userData.tap_power_boosts : 0,
    getEnergyRefillBoosts: (state) => state.userData ? state.userData.energy_refill_boosts : 0,
    getTapPowerBoostEndTime: (state) => state.tapPowerBoostEndTime,
    getTapPowerBoostCooldown: (state) => {
      if (!state.lastTapPowerBoostUse) return 0
      const elapsedTime = (Date.now() - state.lastTapPowerBoostUse) / 1000
      return Math.max(0, 4 * 60 * 60 - elapsedTime)
    },
    getEnergyRefillBoostCooldown: (state) => {
      if (!state.lastEnergyRefillBoostUse) return 0
      const elapsedTime = (Date.now() - state.lastEnergyRefillBoostUse) / 1000
      return Math.max(0, 4 * 60 * 60 - elapsedTime)
    },
    isWalletConnected: (state) => !!state.walletInfo,
    getWalletAddress: (state) => state.walletInfo ? state.walletInfo.account.address : null,
    getWatchedAdsCount: state => state.watchedAds,
  }
})
