import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { TonConnectUIProvider } from '@townsquarelabs/ui-vue'

axios.defaults.baseURL = '/api'

const app = createApp(App)

app.use(router)
app.use(store)
app.component('TonConnectUIProvider', TonConnectUIProvider)

app.mount('#app')
