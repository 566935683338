<template>
  <div class="loading-screen">
    <div class="loading-content">
      <img src="@/static/skuff.png" alt="Skuff" class="loading-logo">
      <h2 class="loading-text">Loading Skuff Game...</h2>
      <div class="progress-bar">
        <div class="progress" :style="{ width: `${progress}%` }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'

export default {
  name: 'LoadingScreen',
  props: {
    isLoading: Boolean,
  },
  setup(props, { emit }) {
    const progress = ref(0)

    const incrementProgress = () => {
      const interval = setInterval(() => {
        if (progress.value < 100) {
          progress.value += 1
        } else {
          clearInterval(interval)
        }
      }, 50)
    }

    watch(() => props.isLoading, (newValue) => {
      if (!newValue && progress.value >= 100) {
        emit('loading-complete')
      }
    })

    onMounted(() => {
      incrementProgress()
    })

    return { progress }
  }
}
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/static/background.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  text-align: center;
  color: white;
}

.loading-logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.loading-text {
  font-size: 24px;
  margin-bottom: 20px;
}

.progress-bar {
  width: 300px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease;
}
</style>