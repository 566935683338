<template>
  <nav class="navigation">
    <router-link
      v-for="(item, index) in navItems"
      :key="index"
      :to="item.route"
      class="nav-button"
      :class="{ 
        active: $route.name === item.route.name,
        disabled: item.disabled
      }"
      @click.prevent="navigateTo(item);sendAnalytics(item)"
    >
      <component :is="item.icon" class="nav-icon" />
      <span class="nav-label">{{ item.label }}</span>
    </router-link>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Home, Rocket, Users, ClipboardList, Trophy, Wallet } from 'lucide-vue-next'
import axios from 'axios'

export default {
  name: 'NavBar',
  components: {
    Home,
    Rocket,
    Users,
    ClipboardList,
    Trophy,
    Wallet
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const navItems = [
      { icon: Home, label: 'Home', route: { name: 'SkuffGame' }, disabled: false },
      { icon: Rocket, label: 'Upgrade', route: { name: 'Upgrade' }, disabled: false },
      { icon: Users, label: 'Frens', route: { name: 'Frens' }, disabled: false },
      { icon: ClipboardList, label: 'Tasks', route: { name: 'Tasks' }, disabled: false },
      { icon: Trophy, label: 'Rating', route: { name: 'Rating' }, disabled: false },
      { icon: Wallet, label: 'Wallet', route: { name: 'Wallet' }, disabled: false }
    ]

    const navigateTo = (item) => {
      if (!item.disabled) {
        router.push(item.route)
      }
    }

    const sendAnalytics = async (item) => {
      const response = await axios.post('/analytics', {
        route: item.label,
        user_id: store.state.userData.user_id
      });
    }

    return {
      navItems,
      navigateTo,
      sendAnalytics
    }
  }
}
</script>

<style scoped>
.navigation {
  position: fixed;
  bottom: 10px; /* Добавляем отступ снизу */
  left: 10px; /* Добавляем отступ слева */
  right: 10px; /* Добавляем отступ справа */
  width: calc(100% - 20px); /* Уменьшаем ширину на 20px для отступов */
  background-color: rgba(31, 41, 55, 0.8);
  padding: 0.25rem;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  z-index: 1000;
  border-radius: 10px; /* Закругляем края */
}

.nav-button {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  flex: 1;
  text-decoration: none;
}

.nav-button.active .nav-icon {
  filter: brightness(1);
}

.nav-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-icon {
  width: 1rem;
  height: 1rem;
  margin-bottom: 0.125rem;
  filter: brightness(0.5);
}

.nav-label {
  font-size: 0.625rem;
}
</style>
